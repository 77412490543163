<template>
  <div class="app-container">
    <span class="title">服务选择</span>
    <el-cascader
      class="ss"
      v-model="select"
      :show-all-levels="false"
      :emitPath="false"
      :options="shoppingMenuForm"
      placeholder="请选择服务"
      :props="{ expandTrigger: 'hover' }"
      @change="handleChange"
    ></el-cascader>

    <el-form
      ref="goodsForm"
      :model="form"
      :rules="rules"
      label-width="80px"
      style="marginTop:50px"
    >
      <el-form-item label="服务标题" class="font-color">
        <el-input v-model="form.name" style="width:600px" disabled></el-input>
      </el-form-item>
      <el-form-item label="最大数量" class="row font-color">
        <el-input v-model="form.maximum" style="width:80px" disabled></el-input>
      </el-form-item>
      <el-form-item label="最小数量" class="row font-color">
        <el-input
          v-model="form.smallest"
          style="width:80px"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="每个价格" class="row font-color">
        <el-input v-model="form.price" style="width:80px" disabled></el-input>
      </el-form-item>
      <el-form-item label="服务描述">
        <el-input
          type="textarea"
          v-model="form.desciption"
          disabled
          id="textarea"
          class="font-color"
        ></el-input>
      </el-form-item>
      <el-form-item label="购买数量" class="row">
        <div class="numbox">
          <div class="numbox">
            <a href="javascript:;" @click="change(-1)">-</a>
            <input type="text" v-model="num" @change="inputChange" />
            <a href="javascript:;" @click="change(+1)">+</a>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="共计" class="row font-color">
        <el-input
          v-model="form.countMoney"
          style="width:90px"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="链接" prop="url">
        <el-input
          v-model="form.url"
          style="width:600px"
          placeholder="请在此输入链接..."
        ></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          v-model="form.remark"
          type="textarea"
          placeholder="评论服务请在此填入评论内容,每行一条评论..."
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit('goodsForm')">
          下单
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getshoppingMenu, addOrders } from '@/api/shopping'

export default {
  data() {
    return {
      select: [],
      // select: localStorage.getItem('select'),
      shoppingMenuForm: [],
      goodsMap: {},
      form: {
        countMoney: null,
        url: '',
        remark: '',
        goodsId: null,
        amount: null
      },
      num: null,
      id: '',
      rules: {
        url: [
          { required: true, message: '请输入链接', trigger: 'blur' },
          { type: 'url', message: '链接不合法', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    number(input) {
      this.form.countMoney = this.form.price * parseInt(this.num)
    }
  },
  async created() {
    await this.queryData()
    const index0 = Object.keys(this.goodsMap)[0]
    console.log('Object.keys(this.goodsMap)', Object.keys(this.goodsMap))
    const index1 = Object.keys(this.goodsMap[index0])[0]
    const index2 = Object.keys(this.goodsMap[index0][index1])[0]
    // Object.keys(this.goodsMap)
    // this.goodsMap
    this.select = [index0, index1, index2]
    this.form = this.goodsMap[this.select[0]][this.select[1]][this.select[2]]
    this.form.goodsId = this.form.id
    this.num = this.form.smallest
    this.form.countMoney = this.form.price * this.num
  },
  computed: {},
  mounted() {},
  methods: {
    async queryData() {
      const resp = await getshoppingMenu()
      this.shoppingMenuForm = resp.options
      this.goodsMap = resp.goods_tree
    },
    // 提交订单
    async onSubmit(formName) {
      this.form.amount = this.num
      this.$refs[formName].validate(async valid => {
        if (valid) {
          await addOrders(
            this.form.goodsId,
            this.form.amount,
            this.form.url,
            this.form.remark
          )
          this.$router.push('/service/orderList')
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },

    handleChange(val) {
      this.form = this.goodsMap[val[0]][val[1]][val[2]]
      this.form.goodsId = this.form.id
      this.num = this.form.smallest
      this.form.countMoney = this.form.price * this.num
    },
    change(step) {
      let number = parseInt(this.num)
      number += step
      if (number <= this.form.smallest) {
        number = this.form.smallest
        this.$message.error('不能小于最小数量')
      } else if (number >= this.form.maximum) {
        number = this.form.maximum
        this.$message.error('不能大于最大数量')
      }
      this.num = number
      this.form.countMoney = this.form.price * this.num
    },
    inputChange() {
      this.form.countMoney = this.form.price * parseInt(this.num)
    }
  }
}
</script>
<style lang="scss">
.font-color .el-input.is-disabled .el-input__inner,
.font-color.el-textarea.is-disabled .el-textarea__inner {
  color: #888 !important;
}
</style>

<style lang="scss" scoped>
.ss {
  width: 400px;
  margin-left: 20px;
}
.title {
  font-weight: 550;
  color: #606266;
  font-size: 15px;
  margin-left: 12px;
}
.num {
  position: relative;
}
.change {
  display: inline-block !important;
  position: absolute;
  width: 20;
  height: 40;
}

::v-deep.app-container .el-textarea__inner {
  height: 150px;
  width: 40%;
}
// 计数器样式
.numbox {
  display: flex;
  align-items: center;
  .label {
    width: 60px;
    color: #999;
    padding-left: 10px;
  }
  .numbox {
    width: 120px;
    height: 30px;
    border: 1px solid #e4e4e4;
    display: flex;
    > a {
      width: 29px;
      line-height: 28px;
      text-align: center;
      background: #f8f8f8;
      font-size: 16px;
      color: #666;
      &:first-of-type {
        border-right: 1px solid #e4e4e4;
      }
      &:last-of-type {
        border-left: 1px solid #e4e4e4;
      }
    }
    > input {
      width: 60px;
      height: 30px;
      // padding: 0 5px;
      text-align: center;
      border: 1px solid #e4e4e4;
    }
  }
}
.row {
  display: inline-block;
}
</style>
